import { useCallback, useEffect, useRef, useState } from "react"

interface TypewriterProps  {
  time?: number;
  delay?: number;
}

export const TW = ({children,time =  100, delay = 1}: React.PropsWithChildren<TypewriterProps>) => {
  const [output,setOutput] = useState<string | null>(null)

  useEffect(() => {
    const int = setTimeout(() => setOutput(""),delay)
    return () => {
      if(int){
        clearTimeout(int)
      }
    }
  },[])
  
  useEffect(() => {
    if(typeof output !== 'string'){
      return;
    }
    const l = output.length
    if (!children || typeof children !== 'string'){
      return;
    }

    if (l === children.length ) {
      return;
    }
    setTimeout(() => {
      setOutput(children.slice(0, l + 1))
    },time * (Math.random() + 0.5))
  }, [children, time, output])
  return <>{output}</>
}

