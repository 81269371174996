import { useParams
} from "react-router-dom";

export const PrivacyPolicy = () => {
  let { app_name, date } = useParams();

  return (<div style={{ paddingLeft: '2vw', textAlign: 'center' }}>

    <h1>{app_name} Privacy Policy</h1>
    <p>Effective Date: {new Date(Number(date) ?? Number(1698822769341)).toDateString()}</p>

    <h2>1. Introduction</h2>
    <p>The {app_name} team are committed to protecting your personal information and
      your right to privacy. This Privacy Policy outlines how we collect, use, and share your personal information when you
      access or use our website or app. By using this app you consent to the
      practices described in this Privacy Policy.</p>

    <h2>2. Information We Collect</h2>
    <p><strong>a. Personal Information:</strong></p>
    <p>
      
      -When you sign up for an account, we collect your name, email address, and any other information you provide during
        the registration process.
    </p>


      <p>

      - We may collect additional personal information you voluntarily provide when you communicate with us, such as
        through customer support or other inquiries.
   
    </p>

    <p><strong>b. Non-Personal Information:</strong></p>
    <p>

    - When you use the Service, we may automatically collect non-personal information, such as your device's Internet
        Protocol (IP) address, browser type, operating system, and usage data.
  

    </p>

    <h2>3. How We Use Your Information</h2>
    <p>- We use your personal information to create and manage your account, provide you with access to the Service, and
      communicate with you about your account or our services.</p>
    <p>- We may use non-personal information for analytics, to improve our Service's performance, and for other internal
      purposes.</p>

    <h2>4. Cookies and Tracking Technologies</h2>
    <p>- We use cookies and similar tracking technologies to improve your experience on our Service. These technologies help
      us remember your preferences, understand how you use the Service, and customize content.</p>
    <p>- You have the option to disable cookies in your browser settings, but this may affect your ability to access certain
      features of the Service.</p>

    <h2>5. Sharing Your Information</h2>
    <p>- We do not sell, rent, or trade your personal information to third parties.</p>
    <p>- We may share your personal information with trusted service providers who assist us in operating the Service, such
      as hosting providers, customer support services, or analytics providers.</p>

    <h2>6. Security</h2>
    <p>- We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.</p>
    <p>- Despite our efforts, no method of transmission over the Internet or electronic storage is 100% secure. Therefore,
      we cannot guarantee absolute security.</p>

    <h2>7. Your Choices</h2>
    <p>- You have the right to access, update, or delete your personal information. You can do this through your account
      settings or by contacting us.</p>
    <p>- You can opt-out of receiving marketing emails by following the instructions in the email.</p>

    <h2>8. Changes to This Privacy Policy</h2>
    <p>- We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational,
      legal, or regulatory reasons.</p>
    <p>- We will notify you of any significant changes by posting the revised Privacy Policy on this page, and the
      "Effective Date" will be updated.</p>


    <p><strong>[End of Privacy Policy]</strong></p>


  </div>)
}