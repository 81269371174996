import React from 'react';
import './App.css';
import { TW } from './components/Typewriter';
import { Carousel } from './components/Carousel';
import { OurWork } from './pages/OurWork';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";

import { Link, animateScroll as scroll } from "react-scroll";
import { PrivacyPolicy } from './pages/PrivacyPolicy';
const HeaderLink = ({ to, text, main = false , offset}: { main?: boolean, to: string, text: string, offset: number }) => (
  <li>
    <Link
      style={main ? {fontSize : 'large', textDecoration : "underline"} : {}}
      activeClass="active"
      to={to}
      spy={true}
      smooth={true}
      duration={500}
      offset={offset}
    >
      {text}
    </Link>
  </li>
)

const Header = () => (
  <header>

  <nav>
  <ul>
    <HeaderLink main={true} to="appIdea" text='App Idea' offset={20} />
    {/* <HeaderLink to="about" text='About' /> */}
    <HeaderLink to="ourWork" text='Our Work' offset={-80} />
    <HeaderLink to="contact" text='I have an App Idea' offset={-80} />
  </ul>
  </nav>
  </header>
)




function Home() {
  return (
    <div className="App">
      
      <Header/>

      <section className="Welcome section" id="appIdea">
        <h1 style={{textAlign: 'center'}}><TW time={50}>Got an App Idea?</TW></h1>

        <h1 style={{ textAlign: 'center' }}><TW time={50} delay={1000}>Let's get building...</TW></h1>
        {/* <h4 className='scroll_down'>scroll down...</h4> */}
    
        <p>  We're not just developers; we're creators dedicated to simplifying the complex world of software development. Join us in our mission to craft awesome digital experiences.</p>

        
      </section>

      <section  id="ourWork">

        <OurWork/>
       
      </section>

      {/* <section id="about">
        <h2>About Us</h2>
        <p>

        We specialize in creating top-notch iOS and Android apps. Ready to turn your vision into reality? We're here for you!
        </p>

       
      </section> */}
     

      <section className="Welcome section" id="contact">
        <h2>Got an App Idea?</h2>
        <p>

          We specialize in creating top-notch iOS and Android apps. Let's turn your vision into reality!
        </p>
        <button onClick={() => window.location.href = "mailto:info@appidea.au"} className='myButton'>Get In Touch</button>

      </section>

      <footer>
        &copy; 2023 APP IDEA PTY LTD.
        <br></br>ABN 84 670 786 339
      </footer>

    </div>
  );
}

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<Home />} />
          <Route path="/privacy-policy/:app_name/:date" element={<PrivacyPolicy />} />

        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
